<template lang="pug">
li.grouped-list__title
  span.grouped-list__title-text 
    slot(name="title")
  ul.grouped-list-nested.grouped-list
    slot
</template>

<script>
export default {
  props: {

  }
}
</script>